#inputFile {
  display:none;
}

.greyBackground {
  display: flex;
  background-color: #f7f7f7;
  flex-grow: 1;
  flex-direction: row;
}

.buttons {
  display: flex;
  /* height: 32px; */
  background-color: white;
  border-width: 1px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  outline: none;
}

.no-outline {
  outline: none;
}

.border {
  flex-direction: row;
  background-color: white;
  justify-content: flex-start;
  border: 1px solid black;
  border-radius: 5px;
  height: auto;
  padding: 0px 10px 0px 10px;
}

.productList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
