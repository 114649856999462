html {
  height: 100%;
} 

body {
  min-height: 100%;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

button {
  border:none;
}

label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

#root {
  min-height: 100%;
  display: flex;
  width: 100%;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.greyBackground {
  background-color: #f7f7f7;
}

.no-outline {
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container-fluid {
  padding: 0px;
}

.navbarHeight {
  height: 50px;
}

.title-padding {
  padding-top: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-red {
  background-color: #DA5A5A !important;
}

.bg-grey {
  background-color: #C4C4C4 !important;
}
