.table-border {
  border: 1px;
  border-color: #656565;
  border-spacing: 0px;
}

.table-style {
  border-spacing: 0;
  border: 1px solid;
  border-color: #656565;
  border-spacing: 0px;
  background-color: white;
 }

td:first-child {
  width: 120px;
}

th, td {
   text-align: center;
   padding: 1rem;
 }

tr, td:first-child, td:last-child {
   border: 1px solid;
   border-color:#656565;
 }

button:focus {outline:0;}



/* .right-border {
  border-right: 1px solid;
  border-spacing: 0;

  border-color: #656565;
} */


