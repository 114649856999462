.prodElement {
  display: flex;
  height: 140px;
  flex-direction: row;
  flex: 1;
  padding: 15px;
  background-color: white;
  margin-top: 10px;
}

.imageContainer {
  display: flex;
  flex: 0.2;
  /* margin: 15px; */
  justify-content: center;
}



.image {
  display: block;
  max-width: 120px;
  max-height: 120px;
  height: auto;
  width: auto;
  object-fit: contain;
  padding: 0px;
}

.informationContainer {
  display: flex;
  flex: 0.8;
  flex-direction: row;
}

.informationLeft {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  flex: 0.5
}

.informationRight {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0.5
}

.text {
  padding: 5px
}

.buttons {
  display: flex;
  height: 32px;
  background-color: white;
  border-width: 1px;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-radius: 5px;
  outline: none;
}
