.productImg {
  height: 200px;
}

.previewImg {
  height: 50px;
}

/* .carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
  
} */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='grey' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='grey' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}


.multi-carousel-right {
  transform: translateX(33.333%);
}

.multi-carousel-left {
  transform: translateX(-33.333%);
}

@media (max-width: 768px) {
  .carousel-inner .carousel-item > div {
      display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
      display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* display 3 */
  
.multi-carousel-inner .multi-carousel-item-right.active,
.multi-carousel-inner .multi-carousel-item-next {
  transform: translateX(33.333%);
}

.multi-carousel-inner .multi-carousel-item-left.active,
.multi-carousel-inner .multi-carousel-item-prev {
  transform: translateX(-33.333%);
}

.multi-carousel-inner .multi-carousel-item-right,
.multi-carousel-inner .multi-carousel-item-left{ 
transform: translateX(0);
}
