.image {
  display: block;
  max-width: 120px;
  max-height: 120px;
  height: auto;
  width: auto;
  object-fit: contain;
  padding: 0px;
}

