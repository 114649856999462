.dropdown-input {
  outline: none;
  border: none;
  padding: 0;
}

.dropdown-input:focus {
  outline: none;
  border-bottom: 0.5px solid grey;
  padding: 0;
}

.dropdown-item:active {
  background-color: transparent;
}