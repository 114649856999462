.bg {
  background:rgba(255,255,255,0.9);
  height: 70%;
  border-radius: 10px;
}

.text {
  font-size: 13px;
  font-weight: normal;
  color:  #656565;
}
input:focus {
  outline: none;
}

.bg-img {
  background-image: url('https://as.com/deporteyvida/imagenes/2018/02/28/portada/1519830649_122505_1519830777_noticia_normal.jpg');
  background-size: cover;
}
